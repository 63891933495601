import React, { useEffect, useState, useRef } from "react";
import { motion } from "framer-motion";
import BeforeAfterElement from "./BeforeAfterElement";

const BeforeAfterSlide = ({ scale }) => {
    const [isVisible, setIsVisible] = useState(false);
    const slideRef = useRef(null);
    const [activeIndex, setActiveIndex] = useState(0);

    const [deviceType, setDeviceType] = useState("desktop");

    useEffect(() => {
        const updateDeviceType = () => {
            const width = window.innerWidth;
            if (width < 768) {
                setDeviceType("smartphone");
            } else if (width < 1024) {
                setDeviceType("tablet");
            } else {
                setDeviceType("desktop");
            }
        };

        updateDeviceType();

        window.addEventListener("resize", updateDeviceType);
        return () => {
            window.removeEventListener("resize", updateDeviceType);
        };
    }, []);

    useEffect(() => {
        const observerCallback = (entries) => {
            entries.forEach((entry) => {
                setIsVisible(entry.isIntersecting);
            });
        };

        const observerOptions = {
            root: null,
            rootMargin: "0px",
            threshold: 0.1,
        };

        const observer = new IntersectionObserver(
            observerCallback,
            observerOptions
        );

        if (slideRef.current) {
            observer.observe(slideRef.current);
        }

        return () => {
            if (slideRef.current) {
                observer.unobserve(slideRef.current);
            }
        };
    }, []);

    const elementsData = [
        {
            id: 0,
            title: "Обычный матч",
            title_2: "Winline Матч тура",
            description: "Winline превратил центральные матчи РПЛ в грандиозное шоу с выступлением звезд и мультимедийными перфомансами.",
            beforeImg: "/images/slide_3_1.jpg",
            afterImg: "/images/slide_3_2.jpg",
        },
        {
            id: 1,
            title: "Фрибет как у всех",
            title_2: "Безусловный фрибет 3000 рублей",
            description: "Winline избавил игроков от условий и ограничений — безусловный фрибет 3000 рублей доступен всем новым игрокам!",
            beforeImg: "/images/slide_4_1.jpg",
            afterImg: "/images/slide_4_2.jpg",
        },
        {
            id: 2,
            title: "Скучный покер",
            title_2: "Winline Media Poker",
            description: "Winline собрал звезд, поднял ставки и добавил красок привычному покеру.",
            beforeImg: "/images/slide_5_1.jpg",
            afterImg: "/images/slide_5_2.jpg",

        },
        {
            id: 3,
            title: "Блогерский футбол",
            title_2: "Winline Медийная футбольная лига",
            description: "Winline вывел футбол с участием медийных личностей на новый уровень. МФЛ — это новый формат футбола, где зрелищность выходит на первый план.",
            beforeImg: "/images/slide_6_1.jpg",
            afterImg: "/images/slide_6_2.jpg",

        },

        {
            id: 4,
            title: "Программа лояльности",
            title_2: "Winline Priority",
            description: "Winline запустил первую программу лояльности для абсолютно каждого: кэшбэк за любые ставки, ежедневные фрибеты и неограниченные бонусы.",
            beforeImg: "/images/slide_7_1.png",
            afterImg: "/images/slide_7_2.jpg",

        },
        
        {
            id: 5,
            title: "Просто трансляция",
            title_2: "Турбо-трансляция",
            description: "Winline запустил трансляции киберспортивных событий, которые идут быстрее даже официального каста!",
            beforeImg: "/images/slide_8_1.jpg",
            afterImg: "/images/slide_8_2.jpg",
        },
    ];

    const orderedElements = [
        ...elementsData.slice(activeIndex),
        ...elementsData.slice(0, activeIndex),
    ];

    const handleClick = (id) => {
        if (id === activeIndex) return;
        setActiveIndex(id);
    };

    return (
        <motion.div
            ref={slideRef}
            // initial={{ opacity: 0, y: 20 }}
            // animate={isVisible ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
            // transition={{ duration: 0.5 }}
            className="slide-conteiner py-[10vh]"
            style={{ transform: `translate3d(0,0,0) scale(${scale})`, willChange: 'transform, opacity' }}
        >
            <div
                style={{
                    position: "relative",
                }}
            >
                {orderedElements.map((elem, index) => (

                    <motion.div
                        key={elem.id}
                        animate={{
                            transform:
                                deviceType === "smartphone"
                                    ? `translate3d(0, -${index * 16}px, 0)`
                                    : deviceType === "tablet"
                                    ? `translate3d(0, -${index * 24}px, 0)`
                                    : `translate3d(0, -${index * 32}px, 0)`
                        }}
                        transition={{ duration: 0.3, ease: "easeInOut" }}
                        style={{
                            position: "absolute",
                            top: "-20vh",
                            left: 0,
                            width: "100%",
                            zIndex: orderedElements.length - index,
                            willChange: "transform",
                            backfaceVisibility: "hidden"
                        }}
                    >
                    {/* <motion.div
                        key={elem.id}
                        animate={{
                            // y: deviceType === "smartphone" ? `-${index * 6}%` : deviceType === "tablet" ? `-${index * 6.5}%` : `-${index * 8.6}%`,
                            y: deviceType === "smartphone" ? `-${index * 16}px` : deviceType === "tablet" ? `-${index * 24}px` : `-${index * 32}px`,
                            // scale: 1 - index * 0.07,
                            // filter: `contrast(${1 - index * 0.1})`,
                        }}
                        transition={{ duration: 0.5, ease: "easeInOut" }}
                        style={{
                            position: "absolute",
                            top: "-20vh",
                            left: 0,
                            width: "100%",
                            zIndex: orderedElements.length - index,
                        }}
                    >*/}
                        <BeforeAfterElement
                            index={index}
                            id={elem.id}
                            scale={scale}
                            title={elem.title}
                            title_2={elem.title_2}
                            description={elem.description}
                            beforeImg={elem.beforeImg}
                            afterImg={elem.afterImg}
                            isActive={index === 0}
                            isSlideVisible={isVisible}
                            onClick={() => handleClick(elem.id)}
                            onTransparentAction={() =>
                                handleClick(orderedElements[(index + 1) % orderedElements.length].id)
                            }
                        />
                    </motion.div>
                ))}
            </div>
        </motion.div>
    );
};

export default BeforeAfterSlide;
