import React, { useRef, useEffect, useLayoutEffect, useState } from 'react';
import HeroSlide from './slides/HeroSlide';
import VideoSlide from './slides/VideoSlide';
import VideoList from './slides/VideoList';
import LastSlide from './slides/LastSlide';
import BeforeAfterSlide from './slides/BeforeAfterSlide';


const Slider = ({ currentSlide, setCurrentSlide, slidesData }) => {
    const sliderRef = useRef(null);
    const verticalContainerRef = useRef(null);

    const [slideScales, setSlideScales] = useState({});
    const [scrollOffset, setScrollOffset] = useState(0);

    useLayoutEffect(() => {
        const slider = sliderRef.current;
        const verticalContainer = verticalContainerRef.current;
        if (slider && verticalContainer) {
            slider.style.transform = `translate3d(0, 0, 0) scaleX(-1)`;
            const cachedParallaxEls = slider.querySelectorAll('.parallax-container');
            slider.cachedParallaxEls = cachedParallaxEls;
            cachedParallaxEls.forEach((element) => {
                const parentX = element.parentElement.offsetLeft;
                element.style.transform = `translate3d(${parentX}px, 0, 0)`;
            });
        }
    }, []);

    /*
      Синхронизации горизонтального слайдера с вертикальным скроллом
    */
    const syncSliderWithScroll = () => {
        const verticalContainer = verticalContainerRef.current;
        const slider = sliderRef.current;
        if (!verticalContainer || !slider) return;

        // Получаем список кэшированных параллакс-элементов
        const parallaxEls = slider.cachedParallaxEls || [];
        if (!parallaxEls.length) return;

        // Берем последний параллакс-элемент для вычисления общей горизонтальной длины
        const lastParallaxElement = parallaxEls[parallaxEls.length - 1];
        const sliderMaxOffset = lastParallaxElement.parentElement.offsetLeft;

        const verticalMaxScroll = verticalContainer.scrollHeight - verticalContainer.clientHeight;

        // Вычисляем текущую позицию скролла в процентах от общего диапазона
        const ratio = verticalContainer.scrollTop / verticalMaxScroll;

        // Вычисляем новое значение горизонтального смещения на основе найденного отношения
        const newOffsetLeft = ratio * sliderMaxOffset;

        // Сохраняем текущее значение вертикального скролла (для анимаций или других эффектов)
        setScrollOffset(verticalContainer.scrollTop);

        // Устанавливаем новый стиль трансформации для горизонтального слайдера
        slider.style.transform = `translate3d(${newOffsetLeft}px, 0, 0) scaleX(-1)`;

        // Обновляем позицию каждого параллакс-элемента, чтобы сохранить синхронизацию
        parallaxEls.forEach((element) => {
            const parentX = element.parentElement.offsetLeft;
            element.style.transform = `translate3d(${parentX - newOffsetLeft}px, 0, 0)`;
        });
    };

    /*
      Автоскролл после загрузки страницы.
    */
    useEffect(() => {
        const timer = setTimeout(() => {
            const slider = sliderRef.current;
            const verticalContainer = verticalContainerRef.current;
            if (slider && verticalContainer) {
                const firstSlide = slider.querySelector('.slide');
                if (firstSlide) {
                    verticalContainer.scrollTop = firstSlide.offsetHeight * 0.70;
                    syncSliderWithScroll();
                }
            }
        }, 1500);
        return () => clearTimeout(timer);
    }, []);

    /*
      Обработка перехода к слайду, выбранному из меню
    */
    useEffect(() => {
        const slider = sliderRef.current;
        const verticalContainer = verticalContainerRef.current;
        if (!slider || !verticalContainer) return;

        const slides = slider.querySelectorAll('.slide');
        if (slides.length > 0) {
            const slideHeight = slides[0].offsetHeight;
            verticalContainer.scrollTop = slideHeight * currentSlide;
            syncSliderWithScroll();
        }
    }, [currentSlide]);

    /*
      Масштабирование содержимого слайдов.
    */
    useLayoutEffect(() => {
        const updateSlideScale = () => {
            const contentSlides = verticalContainerRef.current.querySelectorAll('.content_slide');
            const newScales = {};
            contentSlides.forEach((slide) => {
                const innerBlock = slide.querySelector('.content-inner');
                if (!innerBlock) return;
                const maxRatio = slide.dataset.maxRatio ? parseFloat(slide.dataset.maxRatio) : 0.7;
                const maxAllowedHeight = window.innerHeight * maxRatio;
                const { height } = innerBlock.getBoundingClientRect();
                let scale = 1;
                console.log('maxRatio', maxRatio, 'height', height, 'maxAllowedHeight', maxAllowedHeight);
                if (height > maxAllowedHeight) {
                    scale = maxAllowedHeight / height;
                }
                console.log('scale', scale);
                newScales[slide.dataset.id] = scale;
            });
            setSlideScales(newScales);
            console.log('___________________________________');
        };

        // Отложенный вызов, чтобы гарантировать, что все ресурсы готовы
        const timer = setTimeout(() => {
            updateSlideScale();
            console.log('Timeout Action');
        }, 1000);

        window.addEventListener("resize", updateSlideScale);
        window.addEventListener("pageshow", updateSlideScale);

        return () => {
            clearTimeout(timer);
            window.removeEventListener("resize", updateSlideScale);
            window.removeEventListener("pageshow", updateSlideScale);
        };
    }, []);

    
    /*
      Кэширование параллакс-элементов
    */
    useEffect(() => {
        const slider = sliderRef.current;
        if (slider) {
            const cachedParallaxEls = slider.querySelectorAll('.parallax-container');
            slider.cachedParallaxEls = cachedParallaxEls;
        }
    }, []);

    useEffect(() => {
        const verticalContainer = verticalContainerRef.current;
        if (!verticalContainer) return;

        let ticking = false;

        const onScroll = () => {
            if (!ticking) {
                requestAnimationFrame(() => {
                    syncSliderWithScroll();
                    ticking = false;
                });
                ticking = true;
            }
        };

        verticalContainer.addEventListener('scroll', onScroll, { passive: true });
        return () => verticalContainer.removeEventListener('scroll', onScroll);
    }, []);

    // Заготовка: Магнит к слайду при прокрутке
    // const buildThresholdList = () => {
    //     let thresholds = [];
    //     for (let i = 0; i <= 1.0; i += 0.01) {
    //         thresholds.push(i);
    //     }
    //     return thresholds;
    // };

    // useEffect(() => {
    //     const verticalContainer = verticalContainerRef.current;
    //     if (!verticalContainer) return;
        
    //     // Получаем все слайды
    //     const slides = verticalContainer.querySelectorAll('.content_slide');
        
    //     const options = {
    //         root: verticalContainer,
    //         threshold: buildThresholdList()
    //     };

    //     const observerCallback = (entries) => {
    //         let maxEntry = null;
    //         entries.forEach(entry => {
    //             if (!maxEntry || entry.intersectionRatio > maxEntry.intersectionRatio) {
    //                 maxEntry = entry;
    //             }
    //         });
    //         if (maxEntry) {
    //             const id = maxEntry.target.dataset.id;  // ожидается, например, "contentSlide-0"
    //             const index = parseInt(id.split('-')[1], 10);
    //             // Предполагаем, что slidesData является массивом и порядок слайдов совпадает
    //             if (typeof slidesData !== 'undefined' && slidesData[index]) {
    //                 const targetVal = slidesData[index].target;
    //                 // Обновляем currentSlide, если таргет отличается
    //                 if (targetVal !== currentSlide) {
    //                     setCurrentSlide(targetVal);
    //                 }
    //             }
    //         }
    //     };

    //     const observer = new IntersectionObserver(observerCallback, options);

    //     slides.forEach(slide => observer.observe(slide));

    //     return () => {
    //         slides.forEach(slide => observer.unobserve(slide));
    //     };
    // }, [currentSlide, setCurrentSlide]);

    return (
        <>
            <div className="slider-container">
                <div ref={sliderRef} className="slider flex" style={{ transform: 'scaleX(-1)' }}>
                    <div className="slide slice_slide-reverse">
                        <div className="parallax-container">
                            <img src="/images/slide_1-1.png" className="parallax-image skew-x-[20deg] lg:object-center" />
                        </div>
                    </div>

                    <div className="slide slice_slide-reverse slice_slide-border hero_slide-2">
                        <div className="parallax-container">
                            <img src="/images/bg/stadium.png" className="parallax-image skew-x-[20deg] lg:object-center" />
                            <div className='skew-x-[20deg] absolute top-0 left-0 w-[200%] h-full bg-gradient-to-r from-black/50 to-transparent'></div>
                        </div>
                    </div>

                    <div className="slide slice_slide-reverse slice_slide-border">
                        <VideoList />
                    </div>

                    <div className="slide slice_slide-reverse slice_slide-border">
                        <div className="absolute top-0 left-0 w-[200vw] h-[150vh] z-10 backdrop-blur-sm"></div>
                        <div className="parallax-container">
                            <img src="/images/bg/stadium.png" className="parallax-image skew-x-[20deg] scale-[2]" />
                        </div>
                    </div>

                    <div className="slide slice_slide-reverse slice_slide-border">
                        <div className="parallax-container">
                            <img src="/images/bg/stadium.png" className="parallax-image skew-x-[20deg] lg:object-center" />
                            <div className='skew-x-[20deg] absolute top-0 left-0 w-[200%] h-full bg-gradient-to-r from-black/50 to-transparent'></div>
                        </div>
                    </div>
                </div>
            </div>

            <div ref={verticalContainerRef} className="vertical-container" >
                <div
                    className="content_slide relative pt-[30vh]"
                    style={{ height: '200vh' }}
                    data-max-ratio={window.matchMedia("(min-width: 768px)").matches ? "0.45" : "0.6"}
                    data-id="contentSlide-0"
                >
                    <div className="content-inner">
                        <HeroSlide
                            scale={slideScales['contentSlide-0'] || 1}
                            currentSlide={currentSlide}
                            setCurrentSlide={setCurrentSlide}
                        />
                    </div>
                </div>

                <div
                    className="content_slide pb-[20vh] relative"
                    style={{ justifyContent: 'flex-end' }}
                    data-max-ratio="0.3"
                    data-id="contentSlide-1"
                >
                    <div className="content-inner">
                        <VideoSlide scale={slideScales['contentSlide-1'] || 1} />

                        <div style={{
                            transform: `translate3d(0, ${scrollOffset * 0.1}px, 0)`,
                            position: 'absolute',
                            bottom: '5vh',
                            left: '0.5vw',
                            width: '15vw',
                            transitionDuration: '1s',
                        }}>
                            <img src="/images/coin_1.png" alt="coin" className="floating-image"
                                style={{ width: '100%', animationDelay: '0.5s' }} />
                        </div>

                        <div style={{
                            transform: `translate3d(0, ${scrollOffset * 0.37}px, 0)`,
                            position: 'absolute',
                            top: '-50vh',
                            right: '-5vw',
                            width: '20vw',
                            transitionDuration: '1s',
                        }}>
                            <img src="/images/coin_2.png" alt="coin" className="floating-image"
                                style={{ width: '100%', animationDelay: '2s' }} />
                        </div>

                        <div style={{
                            transform: `translate3d(0, ${scrollOffset * 0.2}px, 0)`,
                            position: 'absolute',
                            top: '0',
                            left: '-2vw',
                            width: '10vw',
                            transitionDuration: '1s',
                        }}>
                            <img src="/images/coin_3.png" alt="coin" className="floating-image"
                                style={{ width: '100%' }} />
                        </div>
                    </div>
                </div>

                <div
                    className="content_slide relative"
                    data-max-ratio={window.matchMedia("(min-width: 768px)").matches ? "0.15" : "0.3"}
                    data-id="contentSlide-2"
                >
                    <div className="content-inner">
                        <BeforeAfterSlide scale={slideScales['contentSlide-2'] || 1} />
                    </div>

                    <div style={{
                        transform: `translate3d(0, ${scrollOffset * 0.37}px, 0)`,
                        position: 'absolute',
                        top: '-50vh',
                        right: '-5vw',
                        width: '20vw',
                        transitionDuration: '1s',
                    }}>
                        <img src="/images/coin_1.png" alt="coin" className="floating-image"
                            style={{ width: '100%', animationDelay: '1.5s' }} />
                    </div>

                    <div style={{
                        transform: `translate3d(0, ${scrollOffset * 0.1}px, 0)`,
                        position: 'absolute',
                        bottom: '5vh',
                        left: '0.5vw',
                        width: '15vw',
                        transitionDuration: '1s',
                    }}>
                        <img src="/images/coin_4.png" alt="coin" className="floating-image"
                            style={{ width: '100%', animationDelay: '2.5s' }} />
                    </div>
                </div>

                <div
                    className="content_slide relative"
                    data-max-ratio="0.6"
                    data-id="contentSlide-3"
                >
                    <div className="content-inner">
                        <LastSlide scale={slideScales['contentSlide-3'] || 1} />
                    </div>

                    <div style={{
                        transform: `translate3d(0, ${scrollOffset * 0.2}px, 0)`,
                        position: 'absolute',
                        top: '-5vw',
                        left: '20vw',
                        width: '10vw',
                        transitionDuration: '1s',
                    }}>
                        <img src="/images/coin_3.png" alt="coin" className="floating-image"
                            style={{ width: '100%' }} />
                    </div>
                </div>
            </div>
        </>
    );
};

export default Slider;