import React from 'react';
import { motion } from 'framer-motion';

const BurgerMenu = ({ onClick }) => (

    <motion.div
        className="cursor-pointer w-11 h-11 rounded-full flex justify-center items-center"
        onClick={onClick}
        whileTap={{ scale: 0.9 }}
        whileHover={{ backgroundColor: '#FC6D25' }} // Анимация уменьшения при нажатии
    >
        <svg width="25" height="20" viewBox="0 0 25 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M0 1.42857C0 0.639593 0.639593 0 1.42857 0H22.8571C23.6461 0 24.2857 0.639593 24.2857 1.42857C24.2857 2.21755 23.6461 2.85714 22.8571 2.85714H1.42857C0.639595 2.85714 0 2.21755 0 1.42857ZM0 10C0 9.21102 0.639593 8.57143 1.42857 8.57143H22.8571C23.6461 8.57143 24.2857 9.21102 24.2857 10C24.2857 10.789 23.6461 11.4286 22.8571 11.4286H1.42857C0.639595 11.4286 0 10.789 0 10ZM1.42857 17.1429C0.639593 17.1429 0 17.7824 0 18.5714C0 19.3604 0.639593 20 1.42857 20H11.4286C12.2176 20 12.8571 19.3604 12.8571 18.5714C12.8571 17.7824 12.2176 17.1429 11.4286 17.1429H1.42857Z" fill="white" />
        </svg>
    </motion.div>
);

export default BurgerMenu;
