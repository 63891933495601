import React from 'react';

const Logo = () => {
    return (
        <div>
            <img src='/images/logo.svg' alt='logo' className='w-52 md:w-80' />
        </div>
    );
};

export default Logo;
