import React, { useState, useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import VideoItem from '../../components/VideoItem';
import VideoPopup from '../../components/VideoPopup';
import Portal from '../../components/Portal';

const VideoList = () => {
    const [popupVideo, setPopupVideo] = useState(null);

    const videos = [
        { link: 'https://player.vimeo.com/video/1058567267?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479' },
        { link: 'https://player.vimeo.com/video/1058569548?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479' },
    ];

    const [isVisible, setIsVisible] = useState(false);
    const slideRef = useRef(null);

    useEffect(() => {
        const observerCallback = (entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                } else {
                    setIsVisible(false);
                }
            });
        };

        const observerOptions = {
            root: null,
            rootMargin: '0px',
            threshold: window.matchMedia('(min-width: 768px)').matches ? 0.8 : 0.5 // % видимости
        };

        const observer = new IntersectionObserver(observerCallback, observerOptions);
        if (slideRef.current) {
            observer.observe(slideRef.current);
        }

        return () => {
            if (slideRef.current) {
                observer.unobserve(slideRef.current);
            }
        };
    }, []);

    return (
        <>
            <div ref={slideRef} className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-screen h-screen z-50 skew-x-[20deg]'></div>

            <div className="parallax-container flex">
                <div className="w-full h-full skew-x-0 overflow-hidden">
                    <img
                        src="/images/bg/kosicin.jpg"
                        className="w-full h-full object-cover skew-x-[20deg] min-w-[150%] ml-[-50%] object-top-left"
                    />
                </div>
                <Portal>
                    {popupVideo && (
                        <VideoPopup videoLink={popupVideo} onClose={() => setPopupVideo(null)} />
                    )}

                    <motion.div
                        initial={{ display: 'none', opacity: 0, y: 20, translateY: '-50%' }}
                        animate={isVisible ? { display: 'block', opacity: 1, y: 0 } : { display: 'none', opacity: 0, y: 20, translateY: '-50%' }}
                        transition={{ duration: 0.3 }}
                        className='fixed top-[40%] -translate-y-1/2 left-1/2 -translate-x-1/2 w-fit h-fit z-50'
                    >
                        <VideoItem
                            key={1}
                            onPlay={() => setPopupVideo(videos[1].link)}
                        />
                    </motion.div>


                </Portal>
            </div>
        </>
    );
};

export default VideoList;