import React from 'react';
import { motion } from 'framer-motion';
const MouseDownIcon = () => {
    return (
        <motion.div
            animate={{
                y: [0, 10, 0],
            }}
            transition={{
                duration: 1.5,
                ease: "easeInOut",
                repeat: Infinity,
            }}
        >
            <svg className='w-5 lg:w-10' width="53" height="75" viewBox="0 0 53 75" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_113_38)" filter="url(#filter0_b_113_38)">

                    <rect x="2.15503" y="0.983643" width="48.2845" height="71.0363" rx="24.1422" fill="url(#paint0_linear_113_38)" />
                    <path d="M26.0091 74.0694C40.3743 74.0694 52.0182 62.4635 52.0182 48.1451L52.0182 25.9243C52.0182 11.6059 40.3743 4.64885e-07 26.0091 2.99478e-07C11.6439 1.34072e-07 -1.33634e-07 11.6059 -2.98502e-07 25.9243L-5.5436e-07 48.1451C-7.19227e-07 62.4635 11.6439 74.0694 26.0091 74.0694ZM3.71558 25.9243C3.71558 13.6529 13.6976 3.70347 26.0091 3.70347C38.3206 3.70347 48.3026 13.6529 48.3026 25.9243L48.3026 48.1451C48.3026 60.4164 38.3206 70.3659 26.0091 70.3659C13.6976 70.3659 3.71558 60.4164 3.71558 48.1451L3.71558 25.9243Z" fill="white" />
                    <path d="M24.1513 58.63L24.3182 58.63L24.6901 59.0188C25.4122 59.7457 26.5896 59.7494 27.3171 59.0296C27.3208 59.026 27.3244 59.0224 27.328 59.0188L27.6999 58.63L27.8668 58.63L27.8668 58.4636L38.4748 47.9084L35.8368 45.2791L27.8668 53.2412L27.8668 33.6316L24.1513 33.6316L24.1513 53.2412L16.1813 45.2791L13.5433 47.9084L24.1513 58.4636L24.1513 58.63Z" fill="white" />
                    <path d="M27.8396 27.2554L24.1512 27.2554L24.1512 30.9317L27.8396 30.9317L27.8396 27.2554Z" fill="white" />
                    <path d="M27.8396 20.8792L24.1512 20.8792L24.1512 24.5555L27.8396 24.5555L27.8396 20.8792Z" fill="white" />
                    <path d="M27.8396 14.5029L24.1512 14.5029L24.1512 18.1793L27.8396 18.1793L27.8396 14.5029Z" fill="white" />
                </g>
                <defs>
                    <filter id="filter0_b_113_38" x="-4" y="-4" width="60.0182" height="82.0693" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feGaussianBlur in="BackgroundImageFix" stdDeviation="2" />
                        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_113_38" />
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_113_38" result="shape" />
                    </filter>
                    <linearGradient id="paint0_linear_113_38" x1="26.2973" y1="15.5245" x2="26.2973" y2="72.02" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#1A1919" />
                        <stop offset="1" stopColor="#383C3D" stopOpacity="0.34" />
                    </linearGradient>
                    <clipPath id="clip0_113_38">
                        <rect width="52.0182" height="74.0694" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </motion.div>
    );
};

export default MouseDownIcon;
