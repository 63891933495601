import React, { useEffect, useState, useRef } from "react";

import { motion } from "framer-motion";

const LastSlide = ({ scale }) => {
    // const [isVisible, setIsVisible] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    // const slideRef = useRef(null);


    // useEffect(() => {
    //     const observerCallback = (entries) => {
    //         entries.forEach(entry => {
    //             if (entry.isIntersecting) {
    //                 setIsVisible(true);
    //             } else {
    //                 setIsVisible(false);
    //             }
    //         });
    //     };

    //     const observerOptions = {
    //         root: null,
    //         rootMargin: '0px',
    //         threshold: 0.8
    //     };

    //     const observer = new IntersectionObserver(observerCallback, observerOptions);
    //     if (slideRef.current) {
    //         observer.observe(slideRef.current);
    //     }

    //     return () => {
    //         if (slideRef.current) {
    //             observer.unobserve(slideRef.current);
    //         }
    //     };
    // }, []);

    return (
        <motion.div
            // ref={slideRef}
            // initial={{ opacity: 0, y: 20 }}
            // animate={isVisible ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
            // transition={{ duration: 0.5 }}
            className='slide-conteiner py-[10vh]'
        >

            <div style={{ transform: `scale(${scale})` }}>
                <div
                    className="leading-none text-white uppercase font-medium italic max-lg:text-center w-full"
                    style={{ fontSize: 'clamp(24px, 4vw, 52px)' }}
                >
                    Winline делает разницу в твоей игре<br />
                    Лови фрибет, чтобы твой день стал ярче
                </div>

                <div className="max-w-[1550px] mx-auto max-lg:pt-11 pt-28">
                    <motion.a
                        onHoverStart={() => setIsHovered(true)}
                        onHoverEnd={() => setIsHovered(false)}
                        animate={
                            !isHovered
                                ? { scale: [0.95, 1, 0.95] }
                                : { scale: 0.95 }
                        }
                        transition={
                            !isHovered
                                ? { duration: 2, repeat: Infinity, repeatType: "loop", ease: "easeInOut" }
                                : {}
                        }
                        whileTap={{ scale: 0.9 }}
                        href="https://bit.ly/4baM8fp"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="block"
                    >
                        <img src="/images/title.svg" alt="" className="w-full h-full object-contein md:scale-[0.8]" />
                    </motion.a>
                </div>
            </div>

        </motion.div>

    );
};

export default LastSlide;
