import React, { useEffect, useState, useRef } from 'react';
import { motion } from 'framer-motion';


const HeroSlide = ({ scale, currentSlide, setCurrentSlide }) => {
    const [isVisible, setIsVisible] = useState(false);
    const slideRef = useRef(null);

    const handleSelect = (e) => {
        e.preventDefault();
        if (currentSlide === 2) {
            setCurrentSlide(2.01);
        } else {
            setCurrentSlide(2);
        }
    };

    useEffect(() => {
        const observerCallback = (entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                } else {
                    setIsVisible(false);
                }
            });
        };


        const observerOptions = {
            root: null,
            rootMargin: '0px',
            threshold: 0.6
        };

        const observer = new IntersectionObserver(observerCallback, observerOptions);
        if (slideRef.current) {
            observer.observe(slideRef.current);
        }

        return () => {
            if (slideRef.current) {
                observer.unobserve(slideRef.current);
            }
        };
    }, []);

    return (
        <motion.div
            ref={slideRef}
            initial={{ opacity: 0, y: 20 }}
            animate={isVisible ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
            transition={{ duration: 0.3 }}
            className='slide-conteiner'
        >
            <div className='max-w-[556px] pt-[10vh] uppercase italic max-lg:text-center max-lg:mx-auto lg:origin-left' style={{ transform: `scale(${scale})` }}>
                <h1
                    className="font-medium text-white leading-[105%]"

                    style={{ fontSize: 'clamp(48px, 8vw, 77px)' }}
                >
                    Узнай, как мы<br />
                    делаем разницу
                </h1>

                <p className='md:mt-4 text-[#FF6A13]'
                    style={{ fontSize: 'clamp(28px, 8vw, 52px)' }}
                >
                    и забирай фрибет 3000
                </p>
                <div className='hero_btn-container flex flex-col-reverse gap-4 mt-[12vh] md:mt-12 '>
                    <a
                        style={{ WebkitTapHighlightColor: 'transparent' }}
                        href="https://bit.ly/4baM8fp"
                        target="_blank"
                        rel="noopener noreferrer"
                        className='hero_btn-2 flex justify-center items-center p-4 font-medium text-white border-2 border-white rounded-2xl text-2xl duration-300'
                    >
                        Забрать фрибет
                    </a>

                    <a
                        onClick={(e) => {
                            handleSelect(e);
                        }}
                        className='hero_btn-1 flex justify-center items-center p-4 font-medium text-white border-2 border-white rounded-2xl bg-[#FF6A13] text-4xl duration-300 cursor-pointer'
                    >
                        Узнать
                    </a>

                </div>
            </div>

        </motion.div>
    );
};

export default HeroSlide;