import React, { useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import BurgerMenu from './components/BurgerMenu';
import SlidesMenu from './components/SlidesMenu';
import Slider from './slider/Slider';
import Logo from './components/Logo';
import MouseDownIcon from './components/icons/MouseDownIcon';
import Portal from './components/Portal';

const slidesData = [
  {
    target: 0.70,
    title: 'Главная'
  },
  {
    target: 2,
    title: 'Видео'
  },
  {
    target: 3,
    title: 'Разница'
  },
  {
    target: 4,
    title: 'Забрать фрибет'
  },
];


function App() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);

  const handleSelect = (target) => {
    setCurrentSlide(target);
    setMenuOpen(false);
  };


  return (
    <div className="App overflow-hidden w-full">
      <motion.div
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 1.6, duration: 0.5 }}
        className='sticky-header fixed top-0 left-0 w-full z-50'
      >
        <div className='flex justify-between items-center slide-conteiner'>
          <Logo />
          <BurgerMenu onClick={() => setMenuOpen(!menuOpen)} />
        </div>
      </motion.div>

      <Portal>
        <SlidesMenu
          slides={slidesData}
          onSelect={handleSelect}
          isOpen={menuOpen}
          onClose={() => setMenuOpen(false)}
          currentSlide={currentSlide}
        />
      </Portal>

      <Slider currentSlide={currentSlide} setCurrentSlide={setCurrentSlide} slidesData={slidesData}/>


      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 1.6, duration: 0.5 }}
        className='absolute bottom-4 left-1/2 -translate-x-1/2 z-20'
      >
        <MouseDownIcon />
      </motion.div>


    </div>
  );
}

export default App;
