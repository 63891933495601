import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import Logo from './Logo';


const SlidesMenu = ({ slides, onSelect, isOpen, onClose, currentSlide  }) => {
    const [activeIndex, setActiveIndex] = useState(currentSlide); // Состояние для активного индекса

    const handleSelect = (target) => {
        // setActiveIndex(target);
        if (target === currentSlide) {
            target += 0.01;
        }
        onSelect(target);
    };

    // Анимация для появления и исчезновения меню
    const menuVariants = {
        open: {
            x: 0,
            transition: { type: 'linear', duration: 0.5 }
        },
        closed: {
            x: "100%",
            transition: { type: 'linear', duration: 0.5, delay: 0.5 }
        }
    };

    const overlayVariants = {
        initial: { opacity: 0 },
        animate: { opacity: 1, transition: { duration: 0.5, ease: 'easeInOut' } },
        exit: { opacity: 0, transition: { delay: 0.5, duration: 0.5, ease: 'easeInOut' } }
    };

    return (
        <AnimatePresence>
            {isOpen && (
                <div className='relative z-50'>
                    <motion.div
                        className="close cursor-pointer absolute top-0 left-0 w-full h-full min-height-mobile backdrop-blur-lg z-40"
                        style={{
                            background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(248.99deg, rgba(0, 0, 0, 0) 24.76%, rgba(0, 0, 0, 0.8) 100.08%)',
                        }}
                        onClick={onClose}
                        variants={overlayVariants}
                        initial="initial"
                        animate="animate"
                        exit="exit"
                    ></motion.div>

                    <motion.div
                        className="slides-menu bg-[#2C2C2C] fixed top-0 right-0 max-w-96 lg:max-w-[35%] w-full h-full z-50"
                        initial="closed"
                        animate="open"
                        exit="closed"
                        variants={menuVariants}
                    >
                            <motion.button
                                className='close absolute top-4 lg:top-8 right-4 lg:right-8 z-50'
                                onClick={onClose}
                                whileTap={{ scale: 0.9 }}
                                whileHover={{ rotate: 90 }}
                            >
                                <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect x="2.10522" width="25.307" height="2.97729" rx="1.48865" transform="rotate(45 2.10522 0)" fill="#FC6D25" />
                                    <rect x="0.0209961" y="17.8947" width="25.307" height="2.97729" rx="1.48865" transform="rotate(-45 0.0209961 17.8947)" fill="#FC6D25" />
                                </svg>
                            </motion.button>
                            <div className='overflow-y-auto h-full w-full'>
                                <div className='px-8 py-6 flex flex-col gap-9'>
                                    <div className='menu-logo flex justify-center items-center'>
                                        <Logo />
                                    </div>
                                    <div className='flex flex-col items-center gap-3 h-full w-full' >
                                        {slides.map((slide, index) => (
                                            <div 
                                            key={index} 
                                            onClick={() => handleSelect(slide.target)} 
                                            className={`menu-item text-center text-xl lg:text-2xl font-medium cursor-pointer uppercase py-1 ${
                                                activeIndex === slide.target ? "text-[#FC6D25]" : "text-white hover:text-[#FC6D25] duration-300 hover:translate-x-3"
                                            }`}
                                            >
                                                {slide.title || `Slide ${index + 1}`}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                    </motion.div>
                </div>

            )}
        </AnimatePresence>
    );
};

export default SlidesMenu;
