import React, { useRef, useState, useEffect, memo } from "react";
import { motion } from "framer-motion";
// import debounce from "lodash.debounce";

const BeforeAfterElement = ({
    index,
    id,
    scale,
    title,
    title_2,
    description,
    beforeImg,
    afterImg,
    isActive,
    isSlideVisible,
    onClick,
    onTransparentAction,
}) => {
    const [beforeImgWidth, setBeforeImgWidth] = useState(0);
    const [hasSwapped, setHasSwapped] = useState(false);
    const beforeImgRef = useRef(null);

    // const updateBeforeImgWidth = () => {
    //     if (beforeImgRef.current) {
    //         const width = beforeImgRef.current.offsetWidth;
    //         setBeforeImgWidth(width);
    //     }
    // };

    // const handleBeforeImgLoad = () => {
    //     updateBeforeImgWidth();
    // };

    // useEffect(() => {
    //     const debouncedUpdate = debounce(updateBeforeImgWidth, 200);
    //     window.addEventListener("resize", debouncedUpdate);
    //     return () => {
    //         window.removeEventListener("resize", debouncedUpdate);
    //         // Отменяем отложенные вызовы при unmount
    //         debouncedUpdate.cancel();
    //     };
    // }, []);

    useEffect(() => {
        if (!hasSwapped && isActive && isSlideVisible) {
            setHasSwapped(true);
        }
    }, [isActive, isSlideVisible, hasSwapped]);

    // const showAfterImage = hasSwapped;
    // const computedWidth = beforeImgWidth
    //     ? beforeImgWidth + beforeImgWidth * (9 / 16) * Math.tan((20 * Math.PI) / 180)
    //     : 0;

    return (
        <motion.div className={`shape_box ${id % 2 !== 0 ? "reverce" : ""}`}
            onClick={onClick}
            style={{
                transformOrigin: 'top center',
                cursor: index !== 0 ? "pointer" : "default",
                filter: `hue-rotate(${index * 5}deg)`,
                willChange: 'filter, transform'
            }}
            animate={{
                scale: 1 - index * 0.055,
            }}
            transition={{ duration: 0.5, ease: "easeInOut" }}
        >
            <div className="shape_box-header">
                <div style={{ fontSize: "clamp(16px, 4vw, 38px)" }} className="relative">
                    <span className="block">
                        {title_2}
                    </span>

                    {/* <motion.span
                        initial={{ opacity: 1, }}
                        animate={{ opacity: showAfterImage ? 0 : 1}}
                        transition={{ opacity: { duration: 0.5, delay: 0.8 } }}
                    >
                        {title}
                    </motion.span>

                    
                    <motion.span
                        initial={{ opacity: 0, display: "none" }}
                        animate={{ opacity: showAfterImage ? 1 : 0, display: showAfterImage ? "block" : "none" }}
                        // animate={{ opacity: 1, display: "block" }}
                        transition={{ opacity: { duration: 0.5, delay: 1.6 } }}
                    >
                        {title_2}
                    </motion.span> */}
                </div>
                <span
                    className="shape_box-header-transparent"
                    onClick={(e) => {
                        e.stopPropagation();
                        if (onTransparentAction) onTransparentAction();
                    }}
                ></span>
            </div>
            <div className="shape_box-body">
                <div className="shape_box-img" ref={beforeImgRef}>
                    <motion.img
                        src={afterImg}
                    />

                    {/* <motion.img
                        src={beforeImg}
                        className="before-img"
                        onLoad={handleBeforeImgLoad}
                        loading="lazy"
                    />
                    <div className="w-full h-full overflow-hidden">
                        <motion.div
                            className="after-img-container"
                            initial={{ maxWidth: 0 }}
                            animate={{ maxWidth: showAfterImage ? `${computedWidth}px` : 0 }}
                            // animate={{ maxWidth: `${computedWidth}px`}}

                            transition={{ maxWidth: { duration: 0.8, delay: 1.5 } }}
                        >
                            <img
                                src={afterImg}
                                className="after-img skew-x-[20deg]"
                                style={{ minWidth: beforeImgWidth ? `${computedWidth}px` : "auto" }}
                            />
                        </motion.div>
                    </div> */}
                </div>
                <div
                    className="shape_box-text"
                    style={{ fontSize: "clamp(14px, 4vw, 30px)" }}
                >
                    {description}
                </div>
            </div>
        </motion.div>
    );
};

export default memo(BeforeAfterElement);
