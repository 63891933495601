import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const VideoPopup = ({ videoLink, onClose }) => {
    const [isClosing, setIsClosing] = useState(false);

    const handleClose = () => {
        setIsClosing(true); // Устанавливаем состояние закрытия
        setTimeout(onClose, 300); // Вызываем `onClose` с задержкой после завершения анимации
    };

    const overlayVariants = {
        initial: { opacity: 0 },
        animate: { opacity: 1, transition: { duration: 0.5, ease: 'easeInOut' } },
        exit: { opacity: 0, transition: { duration: 0.5, ease: 'easeInOut' } }
    };

    return (
        <AnimatePresence>
            {videoLink && !isClosing && ( // Показываем компонент только если он не в процессе закрытия
                <motion.div
                    className="fixed inset-0 flex justify-center items-center z-[60] cursor-pointer"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.3 }}
                    onClick={handleClose} // Закрытие по клику на оверлей
                >

                    <motion.div
                        className="close cursor-pointer absolute top-0 left-0 w-full h-full min-height-mobile backdrop-blur-lg z-40"
                        style={{
                            zIndex: 1,
                            background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(248.99deg, rgba(0, 0, 0, 0) 24.76%, rgba(0, 0, 0, 0.8) 100.08%)',
                        }}
                        onClick={handleClose}
                        variants={overlayVariants}
                        initial="initial"
                        animate="animate"
                        exit="exit"
                    ></motion.div>

                    <motion.div
                        className="relative w-[90%] max-w-3xl bg-white rounded-lg z-[50]"
                        initial={{ scale: 0.9, opacity: 0 }}
                        animate={{ scale: 1, opacity: 1 }}
                        exit={{ scale: 0.9, opacity: 0 }}
                        transition={{ duration: 0.3 }}
                        onClick={(e) => e.stopPropagation()} // Предотвращение закрытия при клике на контент
                    >
                        {/* <iframe
                            src={videoLink}
                            width="1920"
                            height="1080"
                            className="w-full h-[360px] md:h-[480px] rounded-t-lg"
                            frameBorder="0"
                            allow="autoplay; encrypted-media; fullscreen; picture-in-picture; screen-wake-lock;"
                            allowFullScreen
                        /> */}


                        <iframe
                            src={videoLink}
                            frameborder="0"
                            width="1920"
                            height="1080"
                            className="w-full h-[360px] md:h-[480px] rounded-t-lg"
                            allow="autoplay; fullscreen; picture-in-picture; clipboard-write; encrypted-media" 
                        ></iframe>

                        <button
                            onClick={handleClose}
                            className="absolute top-2 right-2 w-8 h-8 bg-white rounded-full px-3 py-1 hover:rotate-90 flex items-center justify-center duration-500"
                        >
                            <svg
                                className='min-w-6 min-h-6'
                                width="32"
                                height="33"
                                viewBox="0 0 32 33"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M25 7.98975L7 25.9897"
                                    stroke="#0D150E"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M25 25.9897L7 7.98975"
                                    stroke="#0D150E"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </button>
                    </motion.div>
                    <script src="https://player.vimeo.com/api/player.js"></script>
                </motion.div>
            )}
        </AnimatePresence>
    );
};

export default VideoPopup;



// import React from 'react';

// const VideoPopup = ({ videoLink, onClose }) => {
//     return (
//         <div className="fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center z-50">
//             <div className="relative w-[90%] max-w-3xl">
//                 <iframe
//                     src={videoLink}
//                     width="1920" height="1080"
//                     className="w-full h-[360px] md:h-[480px]"
//                     frameBorder="0"
//                     allow="autoplay; encrypted-media; fullscreen; picture-in-picture; screen-wake-lock;"
//                     frameborder="0"
//                     allowfullscreen
//                 />

//                 <button
//                     onClick={onClose}
//                     className="absolute top-2 right-2 text-white bg-red-600 rounded-full px-3 py-1"
//                 >
//                     <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
//                         <path d="M25 7.98975L7 25.9897" stroke="#0D150E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
//                         <path d="M25 25.9897L7 7.98975" stroke="#0D150E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
//                     </svg>

//                 </button>
//             </div>
//         </div>
//     );
// };

// export default VideoPopup;
