import React, { useState } from 'react';
import { motion } from 'framer-motion';

const VideoItem = ({ image, isActive, onClick, onPlay }) => {
    // const [isScaled, setIsScaled] = useState(false);

    const handlePlay = (e) => {
        e.stopPropagation();
        // setIsScaled(true);
        onPlay();
    };

    return (
            <div className='relative max-lg:scale-50'>
                <motion.button
                    className="screen_btn bg-[#FC6D25] h-[72px] w-[72px] rounded-full flex justify-center items-center z-10"
                    onClick={handlePlay}
                    whileHover={{
                        scale: 1.1,
                    }}
                    whileTap={{
                        scale: 0.9,
                    }}
                >
                    <div>
                        <svg
                            className="play"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M9.27222 5.21363C8.69574 4.86697 7.93647 4.94221 7.43934 5.43934C7.14645 5.73223 7 6.11612 7 6.5V17.5C7 17.8839 7.14645 18.2678 7.43934 18.5607C7.93869 19.06 8.70253 19.1337 9.27993 18.7817C9.75114 18.4944 12.8876 16.3213 15.1031 14.7863C16.2732 13.9757 17.1864 13.343 17.3144 13.26C17.4016 13.2035 17.4842 13.1371 17.5607 13.0607C17.8536 12.7678 18 12.3839 18 12C18 11.6161 17.8536 11.2322 17.5607 10.9393C17.4842 10.8629 17.4016 10.7964 17.3144 10.74C17.1905 10.6597 16.3312 10.0639 15.2151 9.28992C12.9942 7.74995 9.75658 5.5049 9.27222 5.21363Z"
                                fill="white"
                            />
                            <path
                                d="M9.27222 5.21363C8.69574 4.86697 7.93647 4.94221 7.43934 5.43934C7.14645 5.73223 7 6.11612 7 6.5V17.5C7 17.8839 7.14645 18.2678 7.43934 18.5607C7.93869 19.06 8.70253 19.1337 9.27993 18.7817C9.75114 18.4944 12.8876 16.3213 15.1031 14.7863C16.2732 13.9757 17.1864 13.343 17.3144 13.26C17.4016 13.2035 17.4842 13.1371 17.5607 13.0607C17.8536 12.7678 18 12.3839 18 12C18 11.6161 17.8536 11.2322 17.5607 10.9393C17.4842 10.8629 17.4016 10.7964 17.3144 10.74C17.1905 10.6597 16.3312 10.0639 15.2151 9.28992C12.9942 7.74995 9.75658 5.5049 9.27222 5.21363Z"
                                stroke="white"
                            />
                        </svg>
                    </div>

                </motion.button>

                {/* Анимация для теней */}
                <motion.span
                    className="btn_shadow shadow_1"
                    animate={{
                        scale: [1, 1.5, 1],
                        opacity: [0.1, 0.3, 0.1],
                    }}
                    transition={{ duration: 3, repeat: Infinity, ease: 'easeInOut' }}
                ></motion.span>
                <motion.span
                    className="btn_shadow shadow_2"
                    animate={{
                        scale: [1, 2, 1],
                        opacity: [0.1, 0.3, 0.1],
                    }}
                    transition={{ duration: 3, repeat: Infinity, ease: 'easeInOut' }}
                ></motion.span>

            </div >
    );
};


export default VideoItem;




// import React, { useState, useRef } from 'react';
// import { motion } from 'framer-motion';


// const VideoItem = ({ image, link = '' }) => {
//     const [isClicked, setIsClicked] = useState(false); // Управление состоянием клика
//     const ref = useRef(null); // Реф для отслеживания видимости

//     return (
//         <motion.div
//             initial={{
//                 x: '-100%',
//             }}
//             animate={{
//                 x: 0,
//             }}
//             className="w-full max-w-[800px] aspect-video h-auto relative"
//             transition={{ duration: 0.3, ease: 'easeInOut' }}
//         >

//             <img
//                 src={image}
//                 className="absolute top-0 left-0 w-full h-full object-cover"
//             />

//             <motion.button
//                 className="screen_btn bg-[#FC6D25] h-[72px] w-[72px] rounded-full flex justify-center items-center z-10 absolute top-1/2 left-1/2"
//                 style={{ translateX: '-50%', translateY: '-50%' }}
//                 whileHover={{
//                     scale: 1.1,
//                 }}
//                 whileTap={{
//                     scale: 1,
//                 }}
//             >
//                 <div>
//                     <svg
//                         className="play"
//                         width="24"
//                         height="24"
//                         viewBox="0 0 24 24"
//                         fill="none"
//                         xmlns="http://www.w3.org/2000/svg"
//                     >
//                         <path
//                             d="M9.27222 5.21363C8.69574 4.86697 7.93647 4.94221 7.43934 5.43934C7.14645 5.73223 7 6.11612 7 6.5V17.5C7 17.8839 7.14645 18.2678 7.43934 18.5607C7.93869 19.06 8.70253 19.1337 9.27993 18.7817C9.75114 18.4944 12.8876 16.3213 15.1031 14.7863C16.2732 13.9757 17.1864 13.343 17.3144 13.26C17.4016 13.2035 17.4842 13.1371 17.5607 13.0607C17.8536 12.7678 18 12.3839 18 12C18 11.6161 17.8536 11.2322 17.5607 10.9393C17.4842 10.8629 17.4016 10.7964 17.3144 10.74C17.1905 10.6597 16.3312 10.0639 15.2151 9.28992C12.9942 7.74995 9.75658 5.5049 9.27222 5.21363Z"
//                             fill="white"
//                         />
//                         <path
//                             d="M9.27222 5.21363C8.69574 4.86697 7.93647 4.94221 7.43934 5.43934C7.14645 5.73223 7 6.11612 7 6.5V17.5C7 17.8839 7.14645 18.2678 7.43934 18.5607C7.93869 19.06 8.70253 19.1337 9.27993 18.7817C9.75114 18.4944 12.8876 16.3213 15.1031 14.7863C16.2732 13.9757 17.1864 13.343 17.3144 13.26C17.4016 13.2035 17.4842 13.1371 17.5607 13.0607C17.8536 12.7678 18 12.3839 18 12C18 11.6161 17.8536 11.2322 17.5607 10.9393C17.4842 10.8629 17.4016 10.7964 17.3144 10.74C17.1905 10.6597 16.3312 10.0639 15.2151 9.28992C12.9942 7.74995 9.75658 5.5049 9.27222 5.21363Z"
//                             stroke="white"
//                         />
//                     </svg>
//                 </div>
//             </motion.button>

//         </motion.div>
//     );
// };

// export default VideoItem;
